import * as yup from "yup";

import { PlantGrowth } from "../../../../../api/types/plants";
import {
  CafsValueBooleanValidation,
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const growthSchema = yup.object<CafsForm<keyof PlantGrowth>>({
  GrowthRemarks: CafsValueRemarksValidation(),
  VegetationLayerId: CafsValueNumberValidation(),
  MaxHeightM: CafsValueNumberValidation(),
  MinYearsToMaxHeight: CafsValueNumberValidation(),
  HeightMaxGrowthPMonth: CafsValueNumberValidation(),
  EffectSuitabilityOnGrowth: CafsValueNumberValidation(),
  EffectAgeOnGrowth: CafsValueNumberValidation(),
  CanopyMaxGrowthPYearM: CafsValueNumberValidation(),
  TreeCrownDiameterNaturalM: CafsValueNumberValidation(),
  TreePlantingDistanceM: CafsValueNumberValidation(),
  TreeRowWidthM: CafsValueNumberValidation(),
  TreeStripDistanceM: CafsValueNumberValidation(),
  NoOfTreeRowsPerStrip: CafsValueNumberValidation(),
  TreeToppingHeightM: CafsValueNumberValidation(),
  CanopyRadiationInterception: CafsValueNumberValidation(true),
  MeanAnnualIncrementMaxM3PY: CafsValueNumberValidation(),
  TreeHabitId: CafsValueNumberValidation(),
  OffSeasonCanopyCoverReduction: CafsValueNumberValidation(),
  CanopyFractionOfTotalHeight: CafsValueNumberValidation(true),
  RusleCFactor: CafsValueNumberValidation(true),
  RuslePFactor: CafsValueNumberValidation(true),
  PruningAffectsCanopyCover: CafsValueBooleanValidation(),
});
