import { useContext, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantLifeCycle } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useLifecycleData } from "../../../api/useLifeCycleData";
import { useLifecycleUpdate } from "../../../api/useLifecycleUpdate";
import { CafsContext } from "../../../CafsContext";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { multiplyYearlyBaseValuesMapper } from "../../../utils/map-form-data";
import { mapInitialValues } from "../../../utils/map-initial-values";
import { renderCafsTableRow } from "../../../utils/render-cafs-table-row";
import { CafsTable } from "../../cafs-table/CafsTable";
import { SectionRemarks } from "../SectionRemarks";

import { lifecycleSchema } from "./lifecycle-schema";
import { useLifecycleVariables } from "./useLifeCycleVariables";

export function LifeCycleSection({ expanded }: SectionProps) {
  const {
    cafs: { Type },
  } = useContext(CafsContext);
  const variables = useLifecycleVariables(Type);

  const { shouldFetchData } = useFetchData(expanded);
  const { lifeCycleData, isPending, dataUpdatedAt } =
    useLifecycleData(shouldFetchData);
  const { updateLifeCycle, apiErrors } = useLifecycleUpdate({
    onSuccess: () => {},
  });

  const handleSubmit = (data: FieldValues) => {
    updateLifeCycle(
      multiplyYearlyBaseValuesMapper<PlantLifeCycle>(data, variables),
    );
  };

  const methods = useForm<FieldValues>({
    resolver: yupResolver(lifecycleSchema),
    mode: "onChange",
  });

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  useEffect(() => {
    if (lifeCycleData) {
      methods.reset(mapInitialValues(lifeCycleData, variables));
    }
  }, [lifeCycleData, dataUpdatedAt]);

  useApiValidation(apiErrors, methods);

  return lifeCycleData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantLifeCycle>
          name="LifeCycleRemarks"
          data={lifeCycleData}
        />

        <CafsTable
          section={CafsSection.PlantLifeCycle}
          rows={variables}
          render={renderCafsTableRow}
          data={lifeCycleData ?? {}}
          saveDisabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
