import * as yup from "yup";

import {
  CafsValueBooleanValidation,
  CafsValueNumberValidation,
  CafsValueStringValidation,
} from "consts/validation";

import { PlantProduct } from "../../../../../api/types/plants";
import { CafsForm } from "../../../../../types/cafs-form";

export const editProductSchema = yup.object<
  Partial<CafsForm<keyof PlantProduct>>
>({
  DMContentFraction: CafsValueNumberValidation(true),
  NPKAllocFraction: CafsValueNumberValidation(true),
  DegradationAnnualFraction: CafsValueNumberValidation(true),
  HarvestInterimOfStVolFraction: CafsValueNumberValidation(true),
  BurnAtHarvestFraction: CafsValueNumberValidation(true),
  RemoveAtHarvestFraction: CafsValueNumberValidation(true),
  PriceLowOrHigh: CafsValueNumberValidation(true),
  HarvestFullRhythmMonths: CafsValueNumberValidation(),
  HarvestInterimRhythmMonths: CafsValueNumberValidation(),
  DecompositionRate: CafsValueNumberValidation(),
  HarvestYears: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "HarvestYears may only contain digits (0 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  HarvestMonths: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "HarvestMonths may only contain digits (0 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  HarvestAgeMonths: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "HarvestAgeMonths may only contain digits (0 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  HarvestFractions: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9.;,]*$/, {
        message:
          "HarvestFractions may only contain digits (0 to 9), semi-colons and dots",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  Y1stProd: CafsValueNumberValidation(),
  PriceHighPKg: CafsValueNumberValidation(),
  HarvestKgFreshPHour: CafsValueNumberValidation(),
  PriceLowOfHigh: CafsValueNumberValidation(true),
  YWithOptProd: CafsValueNumberValidation(),
  ProdEndCycleFraction: CafsValueNumberValidation(true),
  MaxFreshProductionAnnual: CafsValueNumberValidation(),
  ProductFreshName: CafsValueStringValidation(),
  ProductDryName: CafsValueStringValidation(),
  YToOptProd: CafsValueNumberValidation(),
  GeneralRemarks: CafsValueStringValidation(),
  Code: CafsValueStringValidation(),
  ProductId: CafsValueNumberValidation(),
  Category: CafsValueStringValidation(),
  PlantPart: CafsValueStringValidation(),
  NkgPTDM: CafsValueNumberValidation(),
  PkgPTDM: CafsValueNumberValidation(),
  KkgPTDM: CafsValueNumberValidation(),
  PriceValidForDryProduct: CafsValueBooleanValidation(),
  IsBelowGroundBiomass: CafsValueBooleanValidation(),
});
