import * as yup from "yup";

import { InputDefinition } from "../../../../../api/types/inputs";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
  CafsValueStringValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const definitionValidationSchema = yup.object<
  CafsForm<keyof InputDefinition>
>({
  DefinitionRemarks: CafsValueRemarksValidation(),
  BiomDMN: CafsValueNumberValidation(),
  BiomDMP: CafsValueNumberValidation(),
  BiomDMK: CafsValueNumberValidation(),
  FreeNitrogenContent: CafsValueNumberValidation(true),
  DecompositionRate: CafsValueNumberValidation(),
  BiomDMTonnePHaPY: CafsValueNumberValidation(),
  EconomicLifeCycle: CafsValueNumberValidation(),
  IrrigationPerMonthMm: CafsValueNumberValidation(),
  RusleFactor: CafsValueNumberValidation(),
  RusleCFactor: CafsValueNumberValidation(true),
  SeasonalInputDMContent: CafsValueNumberValidation(),
  RuslePFactor: CafsValueNumberValidation(true),
  Scope: CafsValueStringValidation(),
  TriggerEvent: CafsValueStringValidation(),
  TriggerInitialMonth: yup.object({
    Base: yup
      .number()
      .min(1)
      .max(12)
      .nullable()
      .transform(value =>
        value === "" || Number.isNaN(value) || value === undefined
          ? null
          : value,
      ),
  }),
  Type: CafsValueStringValidation(),
});
