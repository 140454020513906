import { AutocompleteRow } from "../components/cafs-table/AutocompleteRow";
import { BooleanRow } from "../components/cafs-table/BooleanRow";
import { InfoRow } from "../components/cafs-table/InfoRow";
import { InputRow } from "../components/cafs-table/InputRow";
import { CafsVariableType } from "../const/cafs-variable-type";
import { RenderProductTableRowFnArgs } from "../types/render-product-table-row-fn";

export function renderProductTableRow<Section>({
  row,
  data,
  tooltips,
  section,
  isReadMode,
  productCode,
}: RenderProductTableRowFnArgs<Section>) {
  if (row.type === CafsVariableType.Remarks) return null;

  switch (row.type) {
    case CafsVariableType.Input:
      return (
        <InputRow
          key={row.name as string}
          variableName={row.name as string}
          variableValue={(data || {})[row.name as keyof typeof data]}
          sectionName={section}
          tooltip={(tooltips ?? {})[row.name as keyof typeof data]}
          dataFormat={row.format}
          data={row.data}
          disabled={isReadMode}
          type={row.text ? "text" : "number"}
          productCode={productCode}
        />
      );
    case CafsVariableType.Select:
      return (
        <AutocompleteRow
          key={row.name as string}
          variableName={row.name as string}
          options={row.options}
          variableValue={(data || {})[row.name as keyof typeof data]}
          sectionName={section}
          disabled={isReadMode}
          tooltip={(tooltips ?? {})[row.name as keyof typeof data]}
          productCode={productCode}
        />
      );
    case CafsVariableType.Calculated:
      return (
        <InputRow
          key={row.name as string}
          variableName={row.name as string}
          variableValue={(data || {})[row.name as keyof typeof data]}
          calculationFn={row.calculationFn}
          dependOn={row.dependOn}
          sectionName={section}
          tooltip={(tooltips ?? {})[row.name as keyof typeof data]}
          disabled={isReadMode}
          data={row.data}
          productCode={productCode}
        />
      );
    case CafsVariableType.Boolean:
      return (
        <BooleanRow
          key={row.name as string}
          variableName={row.name as string}
          sectionName={section}
          variableValue={(data || {})[row.name as keyof typeof data]}
          tooltip={(tooltips ?? {})[row.name as keyof typeof data]}
          disabled={isReadMode}
          productCode={productCode}
        />
      );
    case CafsVariableType.Info:
      return (
        <InfoRow
          variableName={row.name as string}
          key={row.name as string}
          sectionName={section}
          tooltip={(tooltips ?? {})[row.name as keyof typeof data]}
          variableValue={(data || {})[row.name as keyof typeof data]}
          productCode={productCode}
        />
      );
    default:
      return <></>;
  }
}
