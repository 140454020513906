import { DataFormat } from "../../../consts/data-format";
import { CafsVariable } from "../../../types/cafs-variable";

type VariableData<T> = {
  [K in keyof T]?: {
    Base?: number | null;
    Parent?: number | null;
    Calculated?: number | null;
    Current?: number | null;
  };
};

export function multiplyYearlyBaseValuesMapper<T>(
  data: VariableData<T>,
  variables: CafsVariable<T>[],
): VariableData<T> {
  const mappedData: VariableData<T> = {};

  variables.forEach(variable => {
    const variableKey = variable.name as keyof T;
    const variableData = data[variableKey];

    if (variableData && variable.format === DataFormat.Year) {
      const baseValue = variableData.Base;
      mappedData[variableKey] = {
        ...variableData,
        Base: typeof baseValue === "number" ? baseValue * 12 : baseValue,
      };
    } else {
      mappedData[variableKey] = variableData;
    }
  });

  return mappedData;
}
